import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Link}  from "react-router-dom";
const Header = () => {
  const [fetchedData, setFetchedData] = useState();
  useEffect(() => {
    (async () => {
      const responseData = await axios.get('https://navines.com/wp-json/wp/v2/menu');
      setFetchedData(responseData.data);
    })();
  }, [setFetchedData]);

  function handleMarquee() {
    const marquee = document.querySelectorAll('.marquee-container');
    let speed = 1;
    marquee.forEach(function (el) {
      // stop animation on mouseenter
      let mouseEntered1 = false;
      document.querySelector('.marquee').addEventListener('mouseenter', function () {mouseEntered1 = true;})
      document.querySelector('.marquee').addEventListener('mouseleave', function () {mouseEntered1 = false})
      const container = el.querySelector('.marquee');
      const content = el.querySelector('.marquee > *');
      //Get total width
      const elWidth = content.offsetWidth;
      //Duplicate content
      let clone = content.cloneNode(true);
      container.appendChild(clone);
      let progress = 1;
      function loop() {
        if (mouseEntered1 === false) { progress = progress - speed; }
        if (progress <= elWidth * -1) { progress = 0; }
        container.style.transform = 'translateX(' + progress + 'px)';
        window.requestAnimationFrame(loop);
      }
      loop();
    });
    function handleSpeedClear() {
      speed = 1;
    }
  };
  handleMarquee(); 

  const handleMenuClick  = (event) => {      
    document.getElementById("serviceMenu").classList.add("menuclose")
    setTimeout(() => {
      document.getElementById("serviceMenu").classList.remove("menuclose")
    }, 1500);
  }

  //var menuLevel1 = !!(fetchedData) ? menuLevel1 = fetchedData.filter(fetchedDatas => fetchedDatas.menu_item_parent === "0") : null;
  //var menuLevel2 = (!!(fetchedData) ? menuLevel2 = fetchedData.filter(fetchedDatas => fetchedDatas.menu_item_parent === "0") : null);
  return (
    <React.Fragment>
      {/*!!(fetchedData) ? 
        menuLevel1.map(data => (
          <li key={data.ID}><Link href={data.url.substring(data.url.lastIndexOf('/') + 1)}>
            {data.title}</Link>
          </li>
        ))
        : null*/}
        <header>
      <div className="marquee-container">
        <div className="marquee">
          <div>
            <Link to="https://www.athletis.co.il/" target="_blank" rel="noopener noreferrer">We Launch a new Shopify store! <span>Shop Now</span> </Link>
            <Link to="https://www.raneno.com/" target="_blank" rel="noopener noreferrer"> We build a new ecommerce project! <span>Visit Now</span> </Link>
            <Link to="https://www.navines.com/ios-app-development-company/" target="_blank" rel="noopener noreferrer">It's time for you to <span>build an app for your business</span>, the holiday season is approaching, do not miss an opportunity. </Link>
            <Link to="https://chrome.google.com/webstore/detail/neoboard-lite/hlfebbnjfhjgdaomhoeeeoobkmlnbini" target="_blank" rel="noopener noreferrer"> We developed innovate extension for worldwide merchants NEOBOARD now available on Google Chrome !<span> Download Now</span> </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <nav className="navbar navbar-expand-md">
          <Link to="/" className="navbar-brand"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/navines-logo.png" alt=" Logo" className="svg" width="176" height="33" /></Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#divNavbar" aria-controls="divNavbar" aria-expanded="false" aria-label="Toggle navigation"> Menu </button>
          <div className="navbar-collapse collapse" id="divNavbar" aria-label="" role="navigation">
            <ul className="list-unstyled navbar-nav">
              <li className="menu-itemcurrent menu-item"><Link className="nav-link" to="/" aria-current="page">Home</Link></li>
              <li className="menu-item menu-item"><Link className="nav-link" to="/navines-studio">Studio</Link></li>
              <li className="menu-item menu-item-has-children dropdown">
                <Link className="nav-link" to="#" data-toggle="dropdown">Tools<span className="caret" data-toggle="dropdown"></span></Link>
                <ul className="list-unstyled dropdown-menu">
                  <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/track-package">Tracking Engine</Link></li>
                  <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/feedback-removal-engine">Feedback Removal Engine</Link></li>
                </ul>
              </li>
              <li className="megamenu menu-item menu-item-has-children dropdown">
                <Link className="nav-link" to="#" data-toggle="dropdown">Services<span className="caret" data-toggle="dropdown"></span></Link>
                <ul className="list-unstyled dropdown-menu" id="serviceMenu">
                  <li className="menu-item menu-item-has-children">
                    <Link className="nav-link" to="#">Web Development</Link>
                    <ul className="list-unstyled dropdown-menu">
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/java-software-development-company">Java</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/angularjs-development-company">AngularJS</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/magento-development-company">Magento</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/wordpress-development-company">WordPress</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/html-developer">HTML</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/shopify-development">Shopify</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link className="nav-link" to="#">eCommerce Solutions</Link>
                    <ul className="list-unstyled dropdown-menu">
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/amazon-account-management-services">Amazon Account Management</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/ebay-account-management-services">eBay Account Management</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/ecommerce-consulting-services">eCommerce Consulting</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/logistics-operations">Logistics Operations</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/amazon-account-health-improvement">Amazon Account Health Improvement</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/amazon-plan-of-action">Amazon Plan of Action (POA)</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/ecommerce-inventory-management">eCommerce Inventory Management</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link className="nav-link" to="#">Competitive Intelligence</Link>
                    <ul className="list-unstyled dropdown-menu">
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/business-consultant">Business Consulting</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/company-analysis">Company Profiling and Analysis</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/competitive-pricing-analysis">Competitive Pricing</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/brand-strategy-consulting">Strategy Consulting</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/virtual-assistant">Virtual Assistant</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link className="nav-link" to="#">Mobile App Development</Link>
                    <ul className="list-unstyled dropdown-menu">
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/ios-app-development-company">IOS App Development</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/android-app-development">Android app development</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/react-native-development-services">React Native</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/ionic-framework-development">Ionic</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/hybrid-mobile-app-development-company">Hybrid</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/windows-mobile-app-development-company">Windows App Development</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link className="nav-link" to="#">Digital Marketing</Link>
                    <ul className="list-unstyled dropdown-menu">
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/seo-services-provider-company">Search Engine Optimization (SEO)</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/ppc-services">Pay Per Click (PPC)</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/social-media-marketing-agency">Social Media Marketing</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/lead-generation-services">Lead Generation</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/reputation-management-firm">Reputation Management</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/email-marketing-firm">Email Marketing</Link></li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-has-children">
                    <Link className="nav-link" to="#">Testing</Link>
                    <ul className="list-unstyled dropdown-menu">
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/integration-testing-services">Integration Testing</Link></li>
                      <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/ui-testing">UI Testing</Link></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/blog">Blog</Link></li>
              <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/portfolio">Portfolio</Link></li>
              <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/about">About</Link></li>
              <li className="menu-item" onClick={handleMenuClick}><Link className="nav-link" to="/contact-us">Contact</Link></li>
            </ul>
          </div>
        </nav>
      </div>
      </header>
    </React.Fragment>
  );
}
export default Header;