import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useParams}  from "react-router-dom";
const PageDetails = () => {
  const [fetchedData, setFetchedData] = useState();
  const {pageSlug} = useParams();
  useEffect(() => {
    (async () => {
      const responseData = await axios.get('https://navines.com/wp-json/wp/v2/pages?slug='+pageSlug);
      setFetchedData(responseData.data[0]);
    })();
  }, [pageSlug]);
  return(
    <React.Fragment>
      {console.log(fetchedData)}
      {!!(fetchedData) ? <div dangerouslySetInnerHTML={{__html:fetchedData.content.rendered}}></div> : null}
    </React.Fragment>
  );
};
export default PageDetails;