import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
const Blog = () => {
  const [postData, setFetchedData] = useState();
  useEffect(() => {
    (async () => {
      const responseData = await axios.get('https://navines.com/wp-json/wp/v2/posts');
      setFetchedData(responseData.data);
    })();
  }, [postData]);
  return (
    <React.Fragment>
      <div className="page-wrapper">
        <div className="container">
          <h1 className="text-center mb-2">Blog</h1>
          <h2 className="text-center h4">Get Current IT Topics at your Fingertips</h2><br /><br />
          <div className="row">
            {!!(postData) ? postData.map((data) => {
              return (
                <div className="col-lg-4" key={data.id}>
                  <div className="post-list-block">
                    <figure className="featured-media"><img src={data.jetpack_featured_media_url} alt="" /></figure>
                    <div className="post-list-text">
                      <div className="entry-header">
                        <strong className="post-title"><Link className="stretched-link" to={`/blog/${data.slug}`}>{data.title.rendered}</Link></strong>
                      </div>
                      <ul className="d-flex justify-content-between list-unstyled post-meta"> <li className="post-author meta-wrapper">  <span className="meta-text"> By {data.yoast_head_json.author} </span> </li> <li className="post-date meta-wrapper">  <span className="meta-text">  {new Date(data.date).toLocaleDateString('en-US')}</span> </li> </ul>
                      <div className="post-inner thin ">
                        <div className="entry-content" dangerouslySetInnerHTML={{__html:data.excerpt.rendered}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Blog;