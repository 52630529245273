import React, { useRef } from 'react';
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item  } from 'react-photoswipe-gallery'
const Studio = () => {
  const layoutRef = useRef();
 
  return (
    <React.Fragment>
      <Gallery layoutRef={layoutRef}>
        <Item original="https://player.vimeo.com/video/449223460?autoplay=1" 
        thumbnail="https://player.vimeo.com/video/449223460?autoplay=1"
        width="1024" height="768">
        {({ ref, open }) => (<img ref={ref} onClick={open} src="https://placekitten.com/80/60?image=1" />)}
      </Item>
      </Gallery>
    </React.Fragment>
  );
};
export default Studio;