import React from "react";
import { Helmet } from 'react-helmet-async';
//import "../../assets/js/externalcall.js";
const TrackPackage = () => {
  
  /*function doTrack() {
    const num = document.getElementById("YQNum").value;
    if (num === "") {
      alert("Enter your number.");
      return;
    }
    YQV5.trackSingle({
      //Required, Specify the container ID of the carrier content.
      YQ_ContainerId: "YQContainer",
      //Optional, specify tracking result height, max height 800px, default is 560px.
      YQ_Height: 560,
      //Optional, select carrier, default to auto identify.
      YQ_Fc: "0",
      //Optional, specify UI language, default language is automatically detected based on the browser settings.
      YQ_Lang: "en",
      //Required, specify the number needed to be tracked.
      YQ_Num: num
    });
  }*/
  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Page</title>
      </Helmet>
      <main className="inner-page tool">
        <div className="first-screen">
          <section className="position-relative tool-sec1">
            <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-dot.png" className="tool-dot1" alt="tool dot" />
            <div className="container">
              <div className="text-center">
                <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-gr.png" alt="Grediunt Image" className="tool gr" />
                <h1> Track <small>and</small> Trace<br /><small>NAVINES Tracking Engine</small> <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-hand.png" className="tool-title-hand" alt="tool-hand" /></h1>
              </div>
            </div>
          </section>
          <section className="tool-sec2">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-lg-6 mx-auto tool-form">
                  <div className="form-row">
                    <div className="col-sm-6 mx-auto">
                      <label htmlFor="TrackInput">Tracking Number:</label>
                    </div>
                    <div className="col-sm-6 mx-auto">
                      <input type="text" className="form-control" id="TrackInput" placeholder="Enter Tracking Number" />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-6 mx-auto">
                      <div className="track-error">Enter Tracking Number</div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-sm-6 mx-auto">
                      <button className="btn btn-secondary btn-block confirm" /*onClick={doTrack()}*/><img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-search.png" alt="Search Image" /> Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div id="textSpeech" className="mt-4">
          <section className="position-relative tool-sec-tool">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="row no-gutters text-center align-items-end">
                  <div className="col-6 bdr-right">
                    <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-map.png" alt="tool-map" className="tool-icon" /><br /><br />
                    <strong>TRACKING<br className="d-sm-none" /> CONTROL</strong>
                  </div>
                  <div className="col-6">
                    <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-prevent.png" alt="tool-prevent" className="tool-icon" /><br /><br />
                    <strong>PREVENT BUYER<br className="d-sm-none" /> CLAIM</strong>
                  </div>
                </div>
              </div>
            </div>
            <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-star.png" className="tool-star" alt="tool star" />
            <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-dot.png" className="tool-dot2" alt="tool dot" />
            <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-plus.png" className="tool-plus" alt="tool plus" />
          </section>
          <section className="text-center">
            <div className="container">
              <div className="row">
                <div className="col-md-10 col-lg-8 col-xl-6 mx-auto">
                  <h2 className="curvebg h3">HOW IT WORKS <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-hand.png" className="tool-title-hand" alt="tool-hand" /></h2>
                  <div className="position-relative">
                    <p>Tracking the package was never so easy before this. Thanks to the smart and fast navines tracking engine. This smart engine is able to search any tracking number from any carrier in the world. Navines Tracking engine enables its users to show the full live result and true information from its sender to the destination country.</p>
                    <p>Once we start searching by destination country we can see a better picture for the status of the package.</p>
                    <p>Avoid the false claim and information of your package with the Navines search engine. Sometimes when the package goes to the shipping location, they show the incorrect information which may lead to confusion. The carriers do not scan each location and transit status which further add more confusion to the state of mind of the buyers. </p>
                    <p>Major marketplaces like eBay and Amazon are the victim of these confusion and it creates a wrong impression about the company. This confusion not only lost the trust of the company but also results in the late claim in case of any defect from the buyer’s side. The answer to say goodbye to all confusion from buyer as well as seller side is the Navines Tracking Engine, where true and live tracking is just a click away. </p>
                    <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-box2.png" className="tool-box2" alt="tool box2" />
                    <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-box3.png" className="tool-box3" alt="tool box3" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>       
        <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-left-shadow.png" className="tool-left-shadow" alt="tool left shadow" />
        <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-right-shadow.png" className="tool-right-shadow" alt="tool Right shadow" />
      </main>
    </React.Fragment >
  );
};
export default TrackPackage;