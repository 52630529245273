import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import TrackPackage from './pages/TrackPackage';
import PageDetails from './pages/PageDetails';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import Studio from './pages/Studio';
import Contact from './pages/Contact';
import Sitemap from './pages/Sitemap';
import NoMatch from './pages/Nomatch';
const Body = () => {   
  return (
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="navines-studio" element={<Studio/>}/>
      <Route path="blog" element={<Blog/>}/>
      <Route path="track-package" element={<TrackPackage/>}/>
      <Route path="blog/:blogSlug" element={<BlogDetail/>}/>
      <Route path=":pageSlug" element={<PageDetails/>}/>     
      <Route path="contact-us" element={<Contact/>}/>
      <Route path="sitemap" element={<Sitemap/>}/>
      <Route path='*' element={<NoMatch/>}/>
    </Routes>
  );
};
export default Body;