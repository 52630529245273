import React from "react";
import { Container, Button } from "react-bootstrap";
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";
import forZeoroFor from '../../assets/img/page-no-found.svg';
import forZeoroForMen1 from '../../assets/img/404-men1.svg';
import forZeoroForMen2 from '../../assets/img/404-men2.svg';
const NoMatch = () => {
  return (
    <React.Fragment>     
      <Helmet>
        <title>404 Page</title>
      </Helmet>
      <section className="no-page-height">
        <Container>
          <h1>ERROR</h1><br/>
          <div className="noimg-sec">
            <img className="no-img1" src={forZeoroForMen1} alt="404" title="404" width={150} height={214}/>
            <img className="no-img" src={forZeoroFor} alt="404" title="404"/>
            <img className="no-img2" src={forZeoroForMen2} alt="404" title="404" width={120} height={234}/>
          </div>   
          <h3>This page is outside of the Universe</h3>
          <p>The page you are trying to access doesn't exist or has been moved.<br/>
          Try going back to our homepage.</p>
          <Button as={Link} to="/">Go to Homepage</Button><br/><br/>
          <small>If you think this is an error, contact our team at <Link to="/contact">Contact Us</Link></small>
        </Container>
      </section>
      {/*<i className="icon-vcard"></i>
      <i className="icon-pin"></i>
      <i className="icon-reply"></i>
      <i className="icon-reply-all"></i>
      <i className="icon-forward"></i>
      <i className="icon-print"></i>
      <i className="icon-keyboard"></i>
      <i className="icon-slider-left"></i>
      <i className="icon-clock-empty"></i>
      <i className="icon-hourglass"></i>
      <i className="icon-login"></i>
      <i className="icon-logout"></i>
      <i className="icon-picture"></i>
      <i className="icon-brush"></i>
      <i className="icon-ccw"></i>
      <i className="icon-cw"></i>
      <i className="icon-refresh"></i>
      <i className="icon-clock"></i>
      <i className="icon-circle-empty"></i>
      <i className="icon-circle-dot"></i>
      <i className="icon-circle"></i>
      <i className="icon-checkbox"></i>
      <i className="icon-checkbox-empty"></i>
      <i className="icon-book"></i>
      <i className="icon-mail-empty"></i>
      <i className="icon-mail"></i>
      <i className="icon-phone"></i>
      <i className="icon-book-open"></i>
      <i className="icon-users-group"></i>
      <i className="icon-male"></i>
      <i className="icon-female"></i>
      <i className="icon-child"></i>
      <i className="icon-star"></i>
      <i className="icon-globe"></i>
      <i className="icon-mobile"></i>
      <i className="icon-tablet-1"></i>
      <i className="icon-laptop"></i>
      <i className="icon-desktop"></i>
      <i className="icon-cog"></i>
      <i className="icon-cogs"></i>
      <i className="icon-down"></i>
      <i className="icon-wrench"></i>
      <i className="icon-sliders"></i>
      <i className="icon-lock"></i>
      <i className="icon-trophy"></i>
      <i className="icon-spin3"></i>
      <i className="icon-spin1"></i>
      <i className="icon-spin2"></i>
      <i className="icon-spin4"></i>
      <i className="icon-spin5"></i>
      <i className="icon-spin6"></i>
      <i className="icon-location"></i>
      <i className="icon-address"></i>
      <i className="icon-up"></i>
      <i className="icon-right"></i>
      <i className="icon-left"></i>
      <i className="icon-music-light"></i>
      <i className="icon-search-light"></i>
      <i className="icon-mail-light"></i>
      <i className="icon-heart-light"></i>
      <i className="icon-dot"></i>
      <i className="icon-user-light"></i>
      <i className="icon-video-light"></i>
      <i className="icon-camera-light"></i>
      <i className="icon-photo-light"></i>
      <i className="icon-attach-light"></i>
      <i className="icon-lock-light"></i>
      <i className="icon-eye-light"></i>
      <i className="icon-tag-light"></i>
      <i className="icon-thumbs-up-light"></i>
      <i className="icon-pencil-light"></i>
      <i className="icon-comment-light"></i>
      <i className="icon-location-light"></i>
      <i className="icon-cup-light"></i>
      <i className="icon-trash-light"></i>
      <i className="icon-doc-light"></i>
      <i className="icon-key-light"></i>
      <i className="icon-database-light"></i>
      <i className="icon-megaphone-light"></i>
      <i className="icon-graduation-light"></i>
      <i className="icon-fire-light"></i>
      <i className="icon-paper-plane-light"></i>
      <i className="icon-cloud-light"></i>
      <i className="icon-globe-light"></i>
      <i className="icon-inbox-light"></i>
      <i className="icon-cart"></i>
      <i className="icon-resize-small"></i>
      <i className="icon-desktop-light"></i>
      <i className="icon-tv-light"></i>
      <i className="icon-wallet-light"></i>
      <i className="icon-t-shirt-light"></i>
      <i className="icon-lightbulb-light"></i>
      <i className="icon-clock-light"></i>
      <i className="icon-diamond-light"></i>
      <i className="icon-shop-light"></i>
      <i className="icon-sound-light"></i>
      <i className="icon-calendar-light"></i>
      <i className="icon-food-light"></i>
      <i className="icon-money-light"></i>
      <i className="icon-params-light"></i>
      <i className="icon-cog-light"></i>
      <i className="icon-beaker-light"></i>
      <i className="icon-note-light"></i>
      <i className="icon-truck-light"></i>
      <i className="icon-behance"></i>
      <i className="icon-bitbucket"></i>
      <i className="icon-cc"></i>
      <i className="icon-codeopen"></i>
      <i className="icon-css3"></i>
      <i className="icon-delicious"></i>
      <i className="icon-digg"></i>
      <i className="icon-dribbble"></i>
      <i className="icon-deviantart"></i>
      <i className="icon-dropbox"></i>
      <i className="icon-drupal"></i>
      <i className="icon-facebook"></i>
      <i className="icon-flickr"></i>
      <i className="icon-foursquare"></i>
      <i className="icon-git"></i>
      <i className="icon-github"></i>
      <i className="icon-gittip"></i>
      <i className="icon-google"></i>
      <i className="icon-gplus"></i>
      <i className="icon-gwallet"></i>
      <i className="icon-hacker-news"></i>
      <i className="icon-html5"></i>
      <i className="icon-instagramm"></i>
      <i className="icon-ioxhost"></i>
      <i className="icon-joomla"></i>
      <i className="icon-jsfiddle"></i>
      <i className="icon-lastfm"></i>
      <i className="icon-linux"></i>
      <i className="icon-linkedin"></i>
      <i className="icon-maxcdn"></i>
      <i className="icon-meanpath"></i>
      <i className="icon-openid"></i>
      <i className="icon-pagelines"></i>
      <i className="icon-paypal"></i>
      <i className="icon-qq"></i>
      <i className="icon-reddit"></i>
      <i className="icon-renren"></i>
      <i className="icon-skype"></i>
      <i className="icon-slack"></i>
      <i className="icon-slideshare"></i>
      <i className="icon-soundcloud"></i>
      <i className="icon-spotify"></i>
      <i className="icon-stackexchange"></i>
      <i className="icon-stackoverflow"></i>
      <i className="icon-steam"></i>
      <i className="icon-stumbleupon"></i>
      <i className="icon-tencent-weibo"></i>
      <i className="icon-trello"></i>
      <i className="icon-tumblr"></i>
      <i className="icon-twitch"></i>
      <i className="icon-twitter"></i>
      <i className="icon-vine"></i>
      <i className="icon-vkontakte"></i>
      <i className="icon-wechat"></i>
      <i className="icon-weibo"></i>
      <i className="icon-windows"></i>
      <i className="icon-wordpress"></i>
      <i className="icon-xing"></i>
      <i className="icon-yelp"></i>
      <i className="icon-youtube"></i>
      <i className="icon-yahoo"></i>
      <i className="icon-lemon"></i>
      <i className="icon-blank"></i>
      <i className="icon-pinterest-circled"></i>
      <i className="icon-heart-empty"></i>
      <i className="icon-lock-open"></i>
      <i className="icon-heart"></i>
      <i className="icon-check"></i>
      <i className="icon-cancel"></i>
      <i className="icon-plus"></i>
      <i className="icon-minus"></i>
      <i className="icon-double-left"></i>
      <i className="icon-double-right"></i>
      <i className="icon-double-up"></i>
      <i className="icon-double-down"></i>
      <i className="icon-help"></i>
      <i className="icon-info"></i>
      <i className="icon-link"></i>
      <i className="icon-quote"></i>
      <i className="icon-attach"></i>
      <i className="icon-eye"></i>
      <i className="icon-tag"></i>
      <i className="icon-menu"></i>
      <i className="icon-pencil"></i>
      <i className="icon-feather"></i>
      <i className="icon-code"></i>
      <i className="icon-home"></i>
      <i className="icon-camera"></i>
      <i className="icon-pictures"></i>
      <i className="icon-video"></i>
      <i className="icon-users"></i>
      <i className="icon-user"></i>
      <i className="icon-user-add"></i>
      <i className="icon-comment-empty"></i>
      <i className="icon-chat-empty"></i>
      <i className="icon-bell"></i>
      <i className="icon-star-empty"></i>
      <i className="icon-dot2"></i>
      <i className="icon-dot3"></i>
      <i className="icon-note"></i>
      <i className="icon-basket"></i>
      <i className="icon-note2"></i>
      <i className="icon-share"></i>
      <i className="icon-doc"></i>
      <i className="icon-doc-text"></i>
      <i className="icon-file-pdf"></i>
      <i className="icon-file-word"></i>
      <i className="icon-file-excel"></i>
      <i className="icon-file-powerpoint"></i>
      <i className="icon-file-image"></i>
      <i className="icon-file-archive"></i>
      <i className="icon-file-audio"></i>
      <i className="icon-file-video"></i>
      <i className="icon-file-code"></i>
      <i className="icon-trash-empty"></i>
      <i className="icon-trash"></i>
      <i className="icon-th-large"></i>
      <i className="icon-th"></i>
      <i className="icon-th-list"></i>
      <i className="icon-flag"></i>
      <i className="icon-attention"></i>
      <i className="icon-play"></i>
      <i className="icon-stop"></i>
      <i className="icon-pause"></i>
      <i className="icon-record"></i>
      <i className="icon-to-end"></i>
      <i className="icon-tools"></i>
      <i className="icon-retweet"></i>
      <i className="icon-to-start"></i>
      <i className="icon-fast-forward"></i>
      <i className="icon-fast-backward"></i>
      <i className="icon-search"></i>
      <i className="icon-slider-right"></i>
      <i className="icon-rss"></i>
      <i className="icon-down-big-1"></i>
      <i className="icon-left-big-1"></i>
      <i className="icon-right-big-1"></i>
      <i className="icon-up-big-1"></i>
      <i className="icon-comment-2"></i>
      <i className="icon-comment"></i>
      <i className="icon-eye-2"></i>
      <i className="icon-tablet"></i>
      <i className="icon-bathtub"></i>
      <i className="icon-comb"></i>
      <i className="icon-calendar"></i>
      <i className="icon-search-alt"></i>
      <i className="icon-white"></i>
      <i className="icon-home-alt"></i>
      <i className="icon-timer"></i>
      <i className="icon-locator"></i>
      <i className="icon-cup"></i>
      <i className="icon-currency"></i>
      <i className="icon-box"></i>
      <i className="icon-quote-left"></i>
      <i className="icon-quote-right"></i>
      <i className="icon-volume-up"></i>
      <i className="icon-volume-off"></i>
      <i className="icon-volume"></i>
      <i className="icon-volume-down"></i>
      <i className="icon-arrow-left-alt"></i>
      <i className="icon-arrow-right-alt"></i>
      <i className="icon-thumbs-up"></i>
      <i className="icon-thumbs-down"></i>
      <i className="icon-speech"></i>
      <i className="icon-sphere"></i>
      <i className="icon-light"></i>
      <i className="icon-apple-mouse"></i>
      <i className="icon-arrow-down"></i>
      <i className="icon-arrow-left"></i>
      <i className="icon-arrow-right"></i>
      <i className="icon-arrow-up"></i>
      <i className="icon-search-2"></i>
      <i className="icon-cd-light"></i>
      <i className="icon-mobile-light"></i>
      <i className="icon-clear-button"></i>
      <i className="icon-binoculars"></i>
      <i className="icon-resize-full"></i>
      <i className="icon-paper-plane"></i>
      <i className="icon-clipboard"></i>
      <i className="icon-email"></i>
      <i className="icon-modem"></i>
      <i className="icon-parking"></i>
      <i className="icon-swimming-pool"></i>
      <i className="icon-vector"></i>
      <i className="icon-target"></i>
      <i className="icon-drawing"></i>
      <i className="icon-1480454204_play-triangle-media-shape"></i>
      <i className="icon-summer"></i>
      <i className="icon-wireless-antenna"></i>
      <i className="icon-floor"></i>
      <i className="icon-home-2"></i>
      <i className="icon-wireless"></i>
      <i className="icon-ironing"></i>
      <i className="icon-down-big"></i>
      <i className="icon-left-big"></i>
      <i className="icon-right-big"></i>
      <i className="icon-up-big"></i>
      <i className="icon-laundry"></i>
      <i className="icon-fax-2"></i>
      <i className="icon-phone-2"></i>
      <i className="icon-tools-2"></i>
      <i className="icon-checkbox-2"></i>
      <i className="icon-map"></i>
      <i className="icon-newspaper"></i>
      <i className="icon-note-2"></i>
      <i className="icon-picture-2"></i>
      <i className="icon-radio-tower"></i>
      <i className="icon-video-2"></i>
      <i className="icon-down-arrow"></i>
      <i className="icon-left-arrow"></i>
      <i className="icon-right-arrow"></i>
      <i className="icon-up-arrow"></i>
      <i className="icon-menu-2"></i>
      <i className="icon-cart-2"></i>
      <i className="icon-attach-1"></i>
      <i className="icon-compass"></i>
      <i className="icon-architect-with-helmet"></i>
      <i className="icon-arrow-point-to-right"></i>
      <i className="icon-design-tool"></i>
      <i className="icon-pantone"></i>
      <i className="icon-location-1"></i>
      <i className="icon-map-1"></i>
      <i className="icon-people"></i>
      <i className="icon-letter"></i>
      <i className="icon-letter-1"></i>
      <i className="icon-map-location"></i>
      <i className="icon-technology"></i>
      <i className="icon-placeholder"></i>
      <i className="icon-telephone"></i>
      <i className="icon-send"></i>
      <i className="icon-left-quote"></i>
      <i className="icon-down-open"></i>
      <i className="icon-left-open"></i>
      <i className="icon-right-open"></i>
      <i className="icon-up-open"></i>
      <i className="icon-delete-cross"></i>
      <i className="icon-play-button"></i>
      <i className="icon-backnew"></i>
      <i className="icon-nextnew"></i>
      <i className="icon-resize-full-alt"></i>*/}
    </React.Fragment>
  );
};
export default NoMatch;