import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
const BlogDetail = () => {
  const [fetchedData, setFetchedData] = useState();
  const { blogSlug } = useParams();
  useEffect(() => {
    (async () => {
      const responseData = await axios.get('https://navines.com/wp-json/wp/v2/posts?slug=' + blogSlug);
      setFetchedData(responseData.data[0]);
    })();
  }, [blogSlug]);
  return (
    <React.Fragment>
      <div className="page-wrapper single-post" id="content">
        <main className="inner-page" id="site-content" role="main">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                {console.log(fetchedData)}
                {!!(fetchedData) ? <h1>{fetchedData.title.rendered}</h1> : null}
                {!!(fetchedData) ? <figure className="featured-media"> <div className="featured-media-inner section-inner"><img src={fetchedData.jetpack_featured_media_url} alt="" /></div></figure> : null}
                {!!(fetchedData) ? <div className="post-meta-wrapper post-meta-single post-meta-single-top"> <ul className="d-flex justify-content-between list-unstyled post-meta"> <li className="post-author meta-wrapper">  <span className="meta-text"> By {fetchedData.yoast_head_json.author} </span> </li> <li className="post-date meta-wrapper">  <span className="meta-text"> {new Date(fetchedData.date).toLocaleDateString('en-US')} </span> </li> </ul> </div> : null}
                {!!(fetchedData) ? <div dangerouslySetInnerHTML={{__html:fetchedData.content.rendered}}></div> : null}
              </div>
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};
export default BlogDetail;