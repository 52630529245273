import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";

const Home = () => {
  const el = useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["future", "Brand"], startDelay: 300,
      typeSpeed: 150,
      backSpeed: 100,
      backDelay: 1000,
      smartBackspace: true,
      loop: true,
      showCursor: true,
    });
    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);
  SwiperCore.use([Pagination, Navigation, Autoplay]);
  return (

    <React.Fragment>
      <main className="home">
        <section className="home-sec1 pb-0">
          <div className="position-relative">
            <div className="container">
              <div className="row homesecrow1 align-items-center">
                <div className="col-md-12">
                  <div className="pink-parent">We create<br />
                    <strong className="border-text">Your</strong><br />
                    <span id="typed"><span ref={el}></span></span>
                  </div>
                </div>
              </div>
              <div className="row text-center">
                <div className="col">
                  <p className="welcome-note">welcome to navines.com</p>
                </div>
              </div>
              <div className="scedule-meeting-sec">
                <h3>Come discover your potential online</h3>
                <p>Let's start a conversation, when are you ready?<br />schedule a meeting with us is <strong>FREE!</strong>. Don't miss that opportunity!</p>
                <a href="https://calendly.com/navines/start" className="btn btn-primary" rel="noopener noreferrer" target="_blank">Schedule Now!</a>
              </div>
              <div className="ads-banner"><a className="ads-img" href="https://www.linkedin.com/posts/orgad-hayak-269366103_bestseoinworld-seo-google-activity-6771622307443023872-QE6G" target="_blank" rel="noopener noreferrer"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/seo-ads2.gif" width="1391" height="151" alt="seo-ads" /></a></div>
            </div>
            <div className="homesec1-ele homesec1-left"><h1>Web &amp; Mobile Application Development Company</h1></div>
          </div>
        </section>
        <section className="home-sec22">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-5 mx-auto">
                <p>Unlock the true potential of business with us. We would love to share our ideas about <strong><a href="/wordpress-development-company">website development</a>, application development, designing, and eCommerce</strong>, everything that falls under the umbrella of Digital.  Come and <strong><a href="mailto:hello@navines.com?subject=Join&nbsp;Us" target="_top">join us</a></strong> for the brightest future.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="home-sec2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="d-table position-relative">
                  <h2>Reformer<br /><span className="border-text">eCommerce</span><br />website builders</h2>
                  <div className="homesec2-right-mob d-lg-none">
                    <a href="https://koshertop.com/" target="_blank" rel="noopener noreferrer">
                      <img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-ecommerce-mobile.png" className="homesec2-right-img1" width="" height="" alt="home-sec2-right" />
                      <img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-ecommerce.png" className="homesec2-right-img2" width="690" height="548" alt="home-sec2-right" />
                    </a>
                  </div>
                  <div className="blockquote">
                    <span className="quorte-start">“</span>
                    <p>Being offline is no more an option for those who want to expand the business beyond geographical limits. Bring your business online with the eCommerce website development company. Take a leap towards a bright future with your customized eCommerce store. We offer an array of Magento development services, Java, and AngularJS. </p>
                    <p className="rotatep">Talk about your ideas and we will make sure to turn your vision into reality by building a user-friendly and customized eCommerce store that matches with the customer as well as the owner's expectations. We as a leading <strong><a href="/ecommerce-consulting">eCommerce development company</a></strong> make sure to give a better shape to your business for an insightful future.<span className="quorte-end">”</span></p>
                  </div>
                  <ul className="list-unstyled payment-logos">
                    <li><img src="https://www.navines.com/wp-content/themes/navines/assets/img/pay-1.png" alt="American-Express" width="62" height="22" /></li>
                    <li><img src="https://www.navines.com/wp-content/themes/navines/assets/img/pay-2.png" alt="Master-Card" width="62" height="37" /></li>
                    <li><img src="https://www.navines.com/wp-content/themes/navines/assets/img/pay-3.png" alt="Visa-Card" width="58" height="37" /></li>
                    <li><img src="https://www.navines.com/wp-content/themes/navines/assets/img/pay-4.png" alt="PayPal" width="62" height="15" /></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1 align-self-center">
                <div className="homesec2-right-desk d-none d-lg-block">
                  <a href="https://koshertop.com/" target="_blank" rel="noopener noreferrer">
                    <picture>
                      <source src="https://www.navines.com/wp-content/themes/navines/assets/img/home-ecommerce-mobile.webp" width="463" height="368" className="homesec2-right-img1" alt="home-sec2-right" type="image/webp" />
                      <source src="https://www.navines.com/wp-content/themes/navines/assets/img/home-ecommerce-mobile.png" width="463" height="368" className="homesec2-right-img1" alt="home-sec2-right" type="image/png" />
                      <img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-ecommerce-mobile.png" width="463" height="368" className="homesec2-right-img1" alt="home-sec2-right" />
                    </picture>
                    <picture>
                      <source src="https://www.navines.com/wp-content/themes/navines/assets/img/ecommerce-website-development.webp" className="homesec2-right-img2" alt="home-sec2-right" width="247" height="235" type="image/webp" />
                      <source src="https://www.navines.com/wp-content/themes/navines/assets/img/ecommerce-website-development.png" className="homesec2-right-img2" alt="home-sec2-right" width="247" height="235" type="image/png" />
                      <img src="https://www.navines.com/wp-content/themes/navines/assets/img/ecommerce-website-development.png" className="homesec2-right-img2" alt="home-sec2-right" width="247" height="235" />
                    </picture>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home-sec3">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 web-development-left">
                <h2 className="d-lg-none">Web<br />Development</h2>
                <div className="position-relative d-table home-web-deve-mobile">
                  <a href="https://www.sellerboosta.com/" target="_blank" rel="noopener noreferrer">
                    <img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-web-development.png" width="640" height="623" alt="home-web-development" className="web-development" />
                    <img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-web-development-shadow.png" alt="home-web-development" className="web-development-shadow" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 offse sec-3-right">
                <h2 className="d-none d-lg-block">Web<br />Development</h2>
                <p className="mb-0">From inception to the final roll-out, your trust as your web development company always inspires us to walk the extra mile. We believe in delivering innovative and state of the art web solutions that grow your business exponentially. With the aim to create something that ease down life motivates us to “seek something different and unique every day”. </p>
              </div>
            </div>
          </div>
        </section>
        <section className="software-dev p-0 clearfix">
          <div className="scroll-ontainer">
            <div className="scroll-area">
              <div className="scroll-block scroll-block1">
                <div className="container">
                  <h2><Link to="java-software-development-company/">Software</Link></h2>
                  <div className="row">
                    <div className="col-lg-10 mx-auto">
                      <p className="moving-spanp">Every business is different and so is the need for software. Hire us for the software development services to unlock the real potential of your business. We make sure to put a smile on your client's face by developing effective software at an affordable package. <br /><br />From software consultation to maintenance, our custom software development company caters to a wide range of software services.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scroll-block scroll-block2">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 mx-auto position-relative">
                      <div className="soft-border">
                        <h4><Link to="/brand-strategy-consulting/">Strategy</Link></h4>
                        <p>The most important aspect of developing software is a strategy. Our team of experts makes sure to develop strategies and blueprints of the software. Based on your customized needs, we make sure to plan out things that match with your needs and goals.   </p>
                        <p className="d-none d-md-block">We help organizations to meet the maximum value of the software with a creative and effective strategy. Each stage of the process is analyzed well before implementing it. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scroll-block scroll-block3 polygons">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 mx-auto">
                      <h4>UX / UI Design</h4>
                      <p>User-Experience is a factor between the success and failure of your digital venture. We help to craft a matchless digital experience for the users. Our UI UX development company focuses more on making it a great appeal and engaging to the users. </p>
                      <p className="d-none d-md-block">Come and enhance your user's experience with Navines. Engineering and the arts are an integral part of our lives today in the global world, especially online, in most cases, you will find excellent engineering product but incorrect design level.</p>

                    </div>
                  </div>
                </div>
              </div>
              <div className="scroll-block scroll-block4">
                <div className="container">
                  <div className="row triangles">
                    <div className="col-lg-10 mx-auto">
                      <h4><Link to="/angularjs-development-company/">Development</Link></h4>
                      <p>Let's roll out all the blueprint into reality with the development of software. We make sure that it should match with your expectations and needs. Once the software is developed, we make sure it gets streamlined to cater to the business needs for the next level of success and growth.<br /><br /> <span className="d-none d-md-inline-block"> Our team of dedicated developers leaves no stone unturned to develop amazing software.</span></p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mobile-app-sec">
          <div className="container">
            <h2 className="d-md-none mb-4">Mobile<br />Application</h2>
            <div className="row align-items-md-center">
              <div className="col-lg-6 mobile-app-left">
                <div className="d-flex align-items-md-center justify-content-around">
                  <span>
                    <a href="https://apps.apple.com/us/app/neoboard/id1504249799" target="_blank" rel="noreferrer noopener"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-mobile-app.png" alt="Mobile-App" width="541" height="569" className="home-mobile-app" /></a>
                    <h6>REAL<br />PROPERTY<br />GAME<br />CHANGER</h6>
                  </span>
                </div>
              </div>
              <div className="col-lg-6 mobile-app-right">
                <h2 className="d-none d-md-block">Mobile<br />Application</h2>
                <p>Our mobile app development service caters the need for different platforms like android, iOS. Turn your idea and innovation into a viable app with a leading IT transformer like us. We are open to talk about the new app, extension or application ideas to create an innovative world. Are you ready for an app that will climb to the next level of success?</p>
                <small>we make sure that the development of your project is streamlined to cater to your business requirements and take your business forward to the next level.</small>
              </div>
            </div>
          </div>
        </section>
        <section className="text-center h-portfolio-sec">
          <div className="container">
            <h2 className="mb-5">Our Recent Projects</h2>
            <Swiper className="home-portfolio" loop={true} autoHeight={true} spaceBetween={20} autoplay={{ delay: 5000, disableOnInteraction: true }} breakpoints={{ 350: { slidesPerView: 2, }, 768: { slidesPerView: 3, }, 992: { slidesPerView: 4, }, 1200: { slidesPerView: 5, } }}>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://koshertop.com/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-koshertop-portfolio.png" width="318" height="210" alt="Koshertop-portfolio" /></a>
                <h5>koshertop</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://ramsport.co.il/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-ramsport-portfolio.png" width="318" height="210" alt="Ramsport-portfolio" /></a>
                <h5>Ramsport</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.numberino.com/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-numberino-portfolio.png" width="318" height="210" alt="Numberino-portfolio" /></a>
                <h5>Numberino</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://revolutionstudio.co.il/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-revolutionstudio-portfolio.png" width="318" height="210" alt="Revolutionstudio-portfolio" /></a>
                <h5>Revolution Studio</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.athletis.co.il/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-athletis-portfolio.png" width="318" height="210" alt="athletis-portfolio" /></a>
                <h5>Athletis</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.raneno.com/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-raneno-portfolio.png" width="318" height="210" alt="raneno-portfolio" /></a>
                <h5>Raneno</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.toyoya.co.il/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-toyoya-portfolio.png" width="318" height="210" alt="toyoya-portfolio" /></a>
                <h5>Toyoya</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.cardshop.co.il/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-cardshop-portfolio.png" width="318" height="210" alt="cardshop-portfolio" /></a>
                <h5>Cardshop</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://sellerboosta.com/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-sellerboosta-portfolio.png" width="318" height="210" alt="Sellerboosta-portfolio" /></a>
                <h5>Sellerboosta</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.neoboard.app/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-neoboard-web-portfolio.png" width="318" height="210" alt="neoboard-portfolio" /></a>
                <h5>Neoboard Web</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://chrome.google.com/webstore/detail/neoboard-lite/hlfebbnjfhjgdaomhoeeeoobkmlnbini" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-neoboard-extension-portfolio.png" width="318" height="210" alt="neoboard-portfolio" /></a>
                <h5>Neoboard Extension</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/app/apple-store/id1504249799" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-neoboard-app-portfolio.png" width="318" height="210" alt="neoboard-portfolio" /></a>
                <h5>Neoboard App</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.navines.washapp" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-washapp-portfolio.png" width="318" height="210" alt="washapp-portfolio" /></a>
                <h5>Washapp</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.washapp.app/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-washapp-web-portfolio.png" width="318" height="210" alt="washapp-portfolio" /></a>
                <h5>Washapp Web</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.zomenu.com/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-zomenu-portfolio.png" width="318" height="210" alt="neoboard-portfolio" /></a>
                <h5>Zomenu</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.navines.zomenu" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-zomenu-app-portfolio.png" width="318" height="210" alt="neoboard-portfolio" /></a>
                <h5>Zomenu</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.dngmedical.co.il/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-dngmedical-portfolio.png" width="318" height="210" alt="dngmedical-portfolio" /></a>
                <h5>DNG Medical</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.katlot.com/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-katlot-portfolio.png" width="318" height="210" alt="katlot-portfolio" /></a>
                <h5>Katlot</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.navines.com/ecommerce/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-ecommerce-portfolio.png" width="318" height="210" alt="eCommerce-portfolio" /></a>
                <h5>eCommerce Operation</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://beyourselfsound.com/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-beyourselfsound-portfolio.png" width="318" height="210" alt="beyourselfsound-portfolio" /></a>
                <h5>Be Yourself Sound</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.newbox.app/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-newbox-portfolio.png" width="318" height="210" alt="newbox-portfolio" /></a>
                <h5>Newbox</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.cryptoimmovables.com/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-crypto-portfilo.png" alt="cryptoimmovables-portfolio" width="318" height="210" /></a>
                <h5>cryptoimmovables</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.labelnow.app/" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-labelnow-portfilo.png" alt="labelnow-portfolio" width="318" height="210" /></a>
                <h5>Labelnow</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.ebay.co.uk/usr/katlot-uk" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-katlotuk-portfolio.png" width="318" height="210" alt="katlotuk-portfolio" /></a>
                <h5>Katlot UK</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.ebay.com/str/theoriginalpairshop" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-katlotusa-portfolio.png" width="318" height="210" alt="katlot usa-portfolio" /></a>
                <h5>Katlot USA</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.navines.yoowallpaper" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-yoowallpaper-portfolio.png" width="318" height="210" alt="yoowallpaper-portfolio" /></a>
                <h5>Yoo Wallpaper</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.navines.hashtag" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-hashtago-portfolio.png" width="318" height="210" alt="hashtago-portfolio" /></a>
                <h5>Hashtago</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/album/065qZKO1s9a4MdQCXhx1qx" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-album-portfolio.png" width="318" height="210" alt="Album-Design-portfolio" /></a>
                <h5>Album Design</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=04AVQeY46D4" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-album-portfolio1.png" width="318" height="210" alt="Album-Design-portfolio" /></a>
                <h5>Album Design</h5>
              </SwiperSlide>
              <SwiperSlide className="h-portfolio-item">
                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=w4dps1HCcog" className="stretched-link"><img src="https://www.navines.com/wp-content/themes/navines/assets/img/home-album-portfolio2.png" width="318" height="210" alt="Album-Design-portfolio" /></a>
                <h5>Album Design</h5>
              </SwiperSlide>
            </Swiper>
            <Link to="/portfolio" className="btn btn-primary">Show More <span className="fas fa-chevron-right"></span></Link>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};
export default Home;