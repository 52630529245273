import React from "react";
import ScrollToTop from './widgets/ScrollToTop';
import {Link}  from "react-router-dom";
const Footer = () => {
  return (
    <React.Fragment>
      <section className="contact-sec">
        <div className="container contact-block">
          <div className="row justify-content-center mt-5" id="contact-us">
            <div className="col-xl-8">
              <h5>Always for you</h5>
              <div className="row">
                <div className="col-md-7 contact-block-left">
                  <p>we'd <strong>love</strong> hear about your project and help you get started.</p>
                  <ul className="list-unstyled contact-support">
                    <li>
                      <p>email <b>us</b></p>
                      <Link to="mailto:hello@navines.com">hello@navines.com</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-5 contact-block-right">
                  <form className="clearfix needs-validation" action="" id="frmDemo" name="contact" method="post" noValidate>
                    <input type="hidden" name="submitted" value="true" />
                    <div className="form-group">
                      <input type="text" className="form-control" id="name" placeholder="Full name" name="name" aria-label="Full name" required />
                      <div className="invalid-feedback">Please enter your name.</div>
                    </div>
                    <div className="form-group">
                      <input type="email" className="form-control" id="email" placeholder="E-mail" aria-label="Type email" name="email" required />
                      <div className="invalid-feedback">Please enter a valid email address.</div>
                    </div>
                    <div className="form-group">
                      <textarea className="form-control" placeholder="Tell us about your project" rows="2" name="message" aria-label="Enter your message" id="message" required></textarea>
                      <div className="invalid-feedback">Please enter message</div>
                    </div>
                    <div className="form-group">
                      <div className="input-group input-group-sm">
                        <div className="input-group-prepend">
                          <span className="input-group-text rounded-0" id="ebcaptchatext"></span>
                        </div>
                        <input type="text" className="form-control rounded-0" id="ebcaptchainput" required />
                        <div className="invalid-feedback" id="captchaAlert">Please enter currect number.</div>
                      </div>
                    </div>
                    <button type="submit">Submit</button>
                  </form>
                </div>
              </div>
              <p className="contact-lable">connect us</p>
            </div>
          </div>
        </div>
      </section>
      <div className="modal fade success-modal" id="myModal">
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">NAVINES</div>
            <div className="modal-body text-center">
              <h4><span>Thank you</span></h4>
              <h5>We will get back to you within <span>24 hours</span></h5>
              <h5>[.............................]</h5>
              <Link to="#" className="btn btn-primary" data-dismiss="modal" aria-label="Close">Ok</Link>
            </div>
          </div>
        </div>
      </div>
      <div id="js-modal-holder" className="modal-holder">
        <div className="modal fade success-modal neoboard-modal" id="neoModal">
          <div className="modal-dialog modal-md modal-dialog-centered" role="document">
            <div className="modal-content" id="neoModalSize">
              <div className="modal-header" id="neoModalTitle"> <span className="text-danger" id="js-close-button" data-dismiss="modal" aria-label="Download">X</span></div>
              <div className="modal-body text-center">
                <h4><span id="flashOffer"></span></h4>
                <h5 id="neoModalText"></h5>
                <h5>[.................]</h5>
                <div id="neoModalBtns"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="row footer-categories mb-5">
            <div className="col-md-3 col-sm-6">
              <strong>Company</strong>
              <ul className="list-unstyled">
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/portfolio">Portfolio</Link></li>
                <li><Link to="#contact-us">Contact Us</Link></li>
                <li><Link to="/blog">Blog</Link></li>
              </ul>
            </div>
            <div className="col-md-3  col-sm-6">
              <strong>Web Development</strong>
              <ul className="list-unstyled">
                <li><Link to="/java-software-development-company">Java</Link></li>
                <li><Link to="/angularjs-development-company">AngularJS</Link></li>
                <li><Link to="/magento-development-company">Magento</Link></li>
                <li><Link to="/wordpress-development-company">WordPress</Link></li>
                <li><Link to="/html-developer">HTML</Link></li>
                <li><Link to="/shopify">Shopify</Link></li>
              </ul>
            </div>
            <div className="col-md-3  col-sm-6">
              <strong>Ecommerce Solutions</strong>
              <ul className="list-unstyled">
                <li><Link to="/amazon-account-management-services">Amazon Account Management (AMA)</Link></li>
                <li><Link to="/ebay-account-management-services">eBay Account Management (EAM)</Link></li>
                <li><Link to="/ecommerce-inventory-management">eCommerce Inventory Management</Link></li>
              </ul>
            </div>
            <div className="col-md-3  col-sm-6">
              <strong>Marketing</strong>
              <ul className="list-unstyled">
                <li><Link to="/seo-services-provider-company">SEO</Link></li>
                <li><Link to="/ppc-services">Pay Per Click (PPC)</Link></li>
                <li><Link to="/social-media-marketing-agency">SMM/ SMO</Link></li>
                <li><Link to="/reputation-management-firm">Online Reputation Management (ORM)</Link></li>
                <li><Link to="/email-marketing-firm">Email Marketing</Link></li>
              </ul>
            </div>

            <div className="col-md-3  col-sm-6">
              <strong>Mobile App Development</strong>
              <ul className="list-unstyled">
                <li><Link to="/ios-app-development-company">IOS App Development</Link></li>
                <li><Link to="/android-app-development">Android App Development</Link></li>
              </ul>
            </div>

            <div className="col-md-3  col-sm-6">
              <strong>Tools</strong>
              <ul className="list-unstyled">
                <li><Link to="/track-package">Tracking Engine</Link></li>
                <li><Link to="/feedback-removal-engine">Feedback Removal Engine</Link></li>
              </ul>
            </div>

            <div className="col-md-3  col-sm-6">
              <strong>Testing</strong>
              <ul className="list-unstyled">
                <li><Link to="/integration-testing-services">Integration Testing</Link></li>
                <li><Link to="/ui-testing">UI Testing</Link></li>
              </ul>
            </div>
            <div className="col-md-3  col-sm-6">
              <strong>Designing</strong>
              <ul className="list-unstyled">
                <li><Link to="/navines-studio">2D Designing</Link></li>
                <li><Link to="/navines-studio">3D Designing</Link></li>
              </ul>
            </div>

          </div>
          <div className="row footer-address mb-5">
            <div className="col-md-3 footer-add-block">
              <span><img src="https://www.navines.com/wp-content/themes/navines/assets/img/flag-israel.png" width="30" height="16" alt="israel-flag" /> Headquarter</span>
              Vinkler Alter 8. Petah Tikva. Israel
            </div>
            <div className="col-md-3 footer-add-block">
              <span><img src="https://www.navines.com/wp-content/themes/navines/assets/img/flag-usa.png" width="30" height="16" alt="israel-usa" /> North America</span>
              2618 Oak St. Santa Ana, California. United States
            </div>
            <div className="col-md-3 footer-add-block">
              <span><img src="https://www.navines.com/wp-content/themes/navines/assets/img/flag-germany.png" width="30" height="16" alt="israel-germany" /> Europe</span>
              Berger Strasse 9. Düsseldorf. Germany
            </div>
            <div className="col-md-3 footer-add-block">
              <span><img src="https://www.navines.com/wp-content/themes/navines/assets/img/flag-ind.png" width="30" height="16" alt="israel-asia" /> ASIA</span>
              Swasthi Business Centre, GUJARAT, INDIA</div>
          </div>
          <div className="footer-bottom">
            <p className="footer-copy">© 2022 NAVINES. All Rights Reserved.</p>
            <ul className="list-unstyled footer-social">
              <li><Link target="_blank" rel="noopener noreferrer" to="https://www.facebook.com/navines.world/"><i className="fab fa-facebook-square"></i></Link></li>
              <li><Link target="_blank" rel="noopener noreferrer" to="https://www.instagram.com/navines.world/"><i className="fab fa-instagram"></i></Link></li>
              <li><Link target="_blank" rel="noopener noreferrer" to="https://twitter.com/navinesworld"><i className="fab fa-twitter-square"></i></Link></li>
              <li><Link target="_blank" rel="noopener noreferrer" to="https://www.linkedin.com/company/navines/"><i className="fab fa-linkedin"></i></Link></li>
              <li><Link target="_blank" rel="noopener noreferrer" to="https://www.pinterest.com/navinesofficial/"><i className="fab fa-pinterest-square"></i></Link></li>
            </ul>
          </div>
        </div>
      </footer>
      <ScrollToTop />
    </React.Fragment>
  );
};
export default Footer;