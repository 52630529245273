import React from "react";
import { Helmet } from 'react-helmet-async';

const Contact = () => {

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Page</title>
      </Helmet>
      <main className="inner-page contact-us">
        <section className="position-relative">
          <div className="container">
            <div className="w-100 text-center">
              <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-gr.png" alt="Grediunt Image" className="tool gr" />
              <h1 className="mb-0"> Contact <small>Us</small><br />
                <img src="https://www.navines.com/wp-content/themes/navines/assets/img/tool-hand.png" className="tool-title-hand" alt="tool-hand" />
              </h1>
              <h2 className="mb-4"><small>TELL US WHAT YOU WANT</small></h2>
              <br />
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <form className="clearfix needs-validation" action="https://www.navines.com/wp-content/themes/navines/contact-mail.php" id="contactFrm" name="contact" method="post" noValidate>
                <input type="hidden" name="submitted" value="true" />
                <div className="form-row d-flex">
                  <div className="form-group col-md-6">
                    <input type="text" className="form-control" id="conName" name="name" placeholder="Full name" aria-label="Full name" required />
                    <div className="invalid-feedback">Please enter your name.</div>
                  </div>
                  <div className="form-group col-md-6">
                    <input type="email" className="form-control" id="conEmail" name="email" placeholder="E-mail" aria-label="Type email" required />
                    <div className="invalid-feedback">Please enter a valid email address.</div>
                  </div>
                </div>
                <div className="form-row d-flex">
                  <div className="form-group col-md-6">
                    <input type="tel" className="form-control" id="conPhone" name="phone" placeholder="Phone number" aria-label="Phone Number" required />
                    <div className="invalid-feedback">Please correct phone number.</div>
                  </div>
                  <div className="form-group col-md-6">
                    <select className="form-control" name="services" id="conService" required>
                      <option defaultValue="">Select Services</option>
                      <option value="Web Design and Development">Web Design &amp; Development</option>
                      <option value="Mobile Apps Development">Mobile Apps Development</option>
                      <option value="SEO Service">SEO Service</option>
                      <option value="Hire developers">Hire developers</option>
                      <option value="Something Else">Something Else</option>
                    </select>
                    <div className="invalid-feedback">Please select service.</div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea className="form-control" name="message" id="conMessage" placeholder="Tell us about your project" rows="2" aria-label="Enter your message" required></textarea>
                  <div className="invalid-feedback">Please enter message</div>
                </div>
                <div className="form-group">
                  <div className="input-group input-group-sm">
                    <div className="input-group-prepend">
                      <span className="input-group-text rounded-0" id="conCaptchaText"></span>
                    </div>
                    <input type="text" className="form-control rounded-0" id="captchaInput" required />
                    <div className="invalid-feedback" id="contCaptchaAlert">Please enter currect number.</div>
                  </div>
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
       
          <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13520.724872711082!2d34.8721249!3d32.0913876!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4c601a340df508b9!2sNAVINES%20-%20Top%20Web%20and%20App%20Development%20Company!5e0!3m2!1sen!2sin!4v1643451148129!5m2!1sen!2sin" width="100%" height="350" allowFullScreen=""></iframe>
        </div>
      </section>
      <img src="https://www.navines.com/wp-content/themes/navines/assets/img/java-left-shadow.svg" alt="Page Left Shadow" className="java-left-shadow svg" />
      <img src="https://www.navines.com/wp-content/themes/navines/assets/img/java-right-shadow.svg" alt="Page Right Shadow" className="java-right-shadow svg" />
    </main>
  </React.Fragment >);
};
export default Contact;